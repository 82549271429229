.post-password-form {
	margin-top: 45px;
	margin-bottom: 55px;
}
.post-password-form-text {
	margin: auto;
	margin-bottom: 15px;
}
.post-password-form-input {
	margin: auto;
	margin-bottom: 20px;
}
.post-password-hint {
	margin-top: 10px;
	color: var(--themecolor);
	opacity: 0.9;
}