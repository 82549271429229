:root {
	--sidebar-width: 240px;

	--themecolor: #5e72e4;
	--themecolor-R: 94;
	--themecolor-G: 114;
	--themecolor-B: 228;
	--themecolor-H: 231;
	--themecolor-S: 71;
	--themecolor-L: 63;
	--themecolor-rgbstr: var(--themecolor-R), var(--themecolor-G), var(--themecolor-B);
	--themecolor-dark0: hsl(var(--themecolor-H), calc(var(--themecolor-S) * 1%), max(calc(var(--themecolor-L) * 1% - 2.5%), 0%));
	--themecolor-dark: hsl(var(--themecolor-H), calc(var(--themecolor-S) * 1%), max(calc(var(--themecolor-L) * 1% - 5%), 0%));
	--themecolor-dark2: hsl(var(--themecolor-H), calc(var(--themecolor-S) * 1%), max(calc(var(--themecolor-L) * 1% - 10%), 0%));
	--themecolor-dark3: hsl(var(--themecolor-H), calc(var(--themecolor-S) * 1%), max(calc(var(--themecolor-L) * 1% - 15%), 0%));
	--themecolor-light: hsl(var(--themecolor-H), calc(var(--themecolor-S) * 1%), min(calc(var(--themecolor-L) * 1% + 10%), 100%));
	--themecolor-gradient: linear-gradient(150deg, var(--themecolor-light) 15%, var(--themecolor) 70%, var(--themecolor-dark0) 94%);

	--color-tint-70: 
		calc(var(--themecolor-R) + (255 - var(--themecolor-R)) * 0.7),
		calc(var(--themecolor-G) + (255 - var(--themecolor-G)) * 0.7),
		calc(var(--themecolor-B) + (255 - var(--themecolor-B)) * 0.7);
	--color-tint-78: 
		calc(var(--themecolor-R) + (255 - var(--themecolor-R)) * 0.78),
		calc(var(--themecolor-G) + (255 - var(--themecolor-G)) * 0.78),
		calc(var(--themecolor-B) + (255 - var(--themecolor-B)) * 0.78);
	--color-tint-80: 
		calc(var(--themecolor-R) + (255 - var(--themecolor-R)) * 0.8),
		calc(var(--themecolor-G) + (255 - var(--themecolor-G)) * 0.8),
		calc(var(--themecolor-B) + (255 - var(--themecolor-B)) * 0.8);
	--color-tint-82: 
		calc(var(--themecolor-R) + (255 - var(--themecolor-R)) * 0.82),
		calc(var(--themecolor-G) + (255 - var(--themecolor-G)) * 0.82),
		calc(var(--themecolor-B) + (255 - var(--themecolor-B)) * 0.82);
	--color-tint-86: 
		calc(var(--themecolor-R) + (255 - var(--themecolor-R)) * 0.86),
		calc(var(--themecolor-G) + (255 - var(--themecolor-G)) * 0.86),
		calc(var(--themecolor-B) + (255 - var(--themecolor-B)) * 0.86);
	--color-tint-92: 
		calc(var(--themecolor-R) + (255 - var(--themecolor-R)) * 0.92),
		calc(var(--themecolor-G) + (255 - var(--themecolor-G)) * 0.92),
		calc(var(--themecolor-B) + (255 - var(--themecolor-B)) * 0.92);
	--color-tint-95: 
		calc(var(--themecolor-R) + (255 - var(--themecolor-R)) * 0.95),
		calc(var(--themecolor-G) + (255 - var(--themecolor-G)) * 0.95),
		calc(var(--themecolor-B) + (255 - var(--themecolor-B)) * 0.95);
	--color-shade-70: 
		calc(30 * 0.7 + var(--themecolor-R) * (1 - 0.7)),
		calc(30 * 0.7 + var(--themecolor-G) * (1 - 0.7)),
		calc(30 * 0.7 + var(--themecolor-B) * (1 - 0.7));
	--color-shade-75: 
		calc(30 * 0.75 + var(--themecolor-R) * (1 - 0.75)),
		calc(30 * 0.75 + var(--themecolor-G) * (1 - 0.75)),
		calc(30 * 0.75 + var(--themecolor-B) * (1 - 0.75));
	--color-shade-80: 
		calc(30 * 0.8 + var(--themecolor-R) * (1 - 0.8)),
		calc(30 * 0.8 + var(--themecolor-G) * (1 - 0.8)),
		calc(30 * 0.8 + var(--themecolor-B) * (1 - 0.8));
	--color-shade-82: 
		calc(30 * 0.82 + var(--themecolor-R) * (1 - 0.82)),
		calc(30 * 0.82 + var(--themecolor-G) * (1 - 0.82)),
		calc(30 * 0.82 + var(--themecolor-B) * (1 - 0.82));
	--color-shade-86: 
		calc(30 * 0.86 + var(--themecolor-R) * (1 - 0.86)),
		calc(30 * 0.86 + var(--themecolor-G) * (1 - 0.86)),
		calc(30 * 0.86 + var(--themecolor-B) * (1 - 0.86));
	--color-shade-90: 
		calc(30 * 0.9 + var(--themecolor-R) * (1 - 0.9)),
		calc(30 * 0.9 + var(--themecolor-G) * (1 - 0.9)),
		calc(30 * 0.9 + var(--themecolor-B) * (1 - 0.9));
	--color-shade-94: 
		calc(30 * 0.94 + var(--themecolor-R) * (1 - 0.94)),
		calc(30 * 0.94 + var(--themecolor-G) * (1 - 0.94)),
		calc(30 * 0.94 + var(--themecolor-B) * (1 - 0.94));
	--color-shade-96: 
		calc(30 * 0.96 + var(--themecolor-R) * (1 - 0.96)),
		calc(30 * 0.96 + var(--themecolor-G) * (1 - 0.96)),
		calc(30 * 0.96 + var(--themecolor-B) * (1 - 0.96));
	--color-tint-blue: 
		calc(204 * 0.6 + var(--themecolor-R) * (1 - 0.6)),
		calc(226 * 0.6 + var(--themecolor-G) * (1 - 0.6)),
		calc(255 * 0.6 + var(--themecolor-B) * (1 - 0.6));

	--color-background: #f4f5f7;
	--color-foreground: #fff;
	--color-widgets: #fff;
	--color-widgets-disabled: #e9ecef;
	--color-border: #dce0e5;
	--color-border-on-foreground: #f3f3f3;
	--color-border-on-foreground-deeper: #eee;
	--color-text-deeper: #212529;
	--color-selection: #cce2ff;
}
html.darkmode body {
	--color-background: #282828;
	--color-foreground: #424242;
	--color-widgets: #555;
	--color-widgets-disabled: #474747;
	--color-border: #777;
	--color-border-on-foreground: #555;
	--color-border-on-foreground-deeper: #777;
	--color-text-deeper: #eee;
	--color-darkmode-toolbar: 33, 33, 33;
	--color-darkmode-banner: #212121;
	--color-selection: #607799;
}
html.darkmode.amoled-dark body,
html.darkmode.amoled-dark.color-immersion body {
	--color-background: #111;
	--color-foreground: #000;
	--color-widgets: #151515;
	--color-widgets-disabled: #000;
	--color-border: #222;
	--color-border-on-foreground: #181818;
	--color-border-on-foreground-deeper: #252525;
	--color-text-deeper: #eee;
	--color-selection: #607799;

	--color-darkmode-toolbar: 0, 0, 0;
	--color-darkmode-banner: #131313;
}

html.color-immersion body {
	--color-background: rgb(var(--color-tint-86));
	--color-foreground: rgb(var(--color-tint-92));
	--color-widgets: rgb(var(--color-tint-95));
	--color-widgets-disabled: rgb(var(--color-tint-86));
	--color-border: rgb(var(--color-tint-78));
	--color-border-on-foreground: rgb(var(--color-tint-86));
	--color-border-on-foreground-deeper: rgb(var(--color-tint-80));
	--color-text-deeper: rgb(var(--color-shade-82));
	--color-selection: rgb(var(--color-tint-70));
}
html.color-immersion.darkmode body {
	--color-background: rgb(var(--color-shade-94));
	--color-foreground: rgb(var(--color-shade-90));
	--color-widgets: rgb(var(--color-shade-86));
	--color-widgets-disabled: rgb(var(--color-shade-82));
	--color-border: rgb(var(--color-shade-80));
	--color-border-on-foreground: rgb(var(--color-shade-82));
	--color-border-on-foreground-deeper: rgb(var(--color-shade-75));
	--color-text-deeper: rgb(var(--color-tint-82));
	--color-selection: rgb(var(--color-shade-70));

	--color-darkmode-toolbar: var(--color-shade-90);
	--color-darkmode-banner: rgb(var(--color-shade-96));
}