#post_comment {
	margin-bottom: 25px;
}
.post-comment-title {
	font-size: 20px;
}
.post-comment-title i {
	margin-right: 5px;
}
.post-comment-reply {
	margin-top: 25px;
	border: 1.2px solid var(--color-border-on-foreground-deeper);
	border-radius: 3px;
	padding: 20px 20px;
}
.post-comment-reply-preview {
	margin-top: 15px;
	border-left: 2px solid var(--color-border-on-foreground-deeper);
	padding: 8px 15px;
	padding-top: 20px;
	padding-bottom: 20px;
	max-height: 250px;
	overflow: hidden;
	position: relative;
}
#post_comment_reply_preview:after {
	content: "";
	background: linear-gradient(180deg, transparent 0%, var(--color-foreground) 100%);
	display: block;
	left: 0;
	right: 0;
	bottom: 0;
	height: 35px;
	position: absolute;
	pointer-events: none;
	transition: all 0.3s ease;
}
#post_comment_reply_cancel {
	margin-top: 20px;
}
#post_comment_content {
	transition: height 0.15s ease, box-shadow 0.3s ease;
	overflow: hidden;
	min-height: 80px;
	resize: none;
	white-space: pre-wrap;
	word-wrap: break-word;
	margin-bottom: 18px;
	margin-top: 20px;
}
#post_comment_content_hidden {
	font-family: inherit !important;
	font-weight: 400;
	line-height: 1.5;
	font-size: 14px;
	padding: 0.625rem 0.75rem;
	width: calc(100% - 30px);
	overflow: hidden;
	white-space: pre-wrap;
	word-wrap: break-word;
	position: absolute;
	pointer-events: none;
	opacity: 0;
}
.post-comment-link-container:before,
.post-comment-captcha-container:before,
#post_comment_toggle_extra_input:before,
.comment-post-checkbox:before {
	pointer-events: none;
	position: absolute;
	top: -35px;
	left: 0px;
	line-height: 25px;
	font-weight: normal;
	color: #fff;
	background: #32325d;
	padding: 3px 10px;
	font-size: 14px;
	border-radius: 3px;
	z-index: 3;
	transition: all 0.3s ease;
	transform: translateY(5px);
	opacity: 0;
	width: max-content;
	width: -moz-max-content;
}
.post-comment-link-container:hover:before,
.post-comment-link-container.active:before,
.post-comment-captcha-container:hover:before,
.post-comment-captcha-container.active:before,
#post_comment_toggle_extra_input:hover:before,
.comment-post-checkbox:hover:before {
	transform: translateY(0);
	opacity: 0.7;
}
.post-comment-link-container:before {
	content: "http(s)://";
}
#post_comment_toggle_extra_input:before {
	content: attr(tooltip-show-extra-field);
	text-transform: none;
}
.show-extra-input #post_comment_toggle_extra_input:before {
	content: attr(tooltip-hide-extra-field);
}
#post_comment_toggle_extra_input {
	border-radius: 100px;
	padding: 5px 20px;
}
#post_comment_toggle_extra_input i {
	transition: all 0.3s ease;
}
.show-extra-input #post_comment_toggle_extra_input i {
	transform: rotateZ(180deg);
}
#post_comment.logged #post_comment_name,
#post_comment.logged #post_comment_email {
	opacity: 1;
	background-color: var(--color-widgets-disabled);
	pointer-events: none;
}
.comment-post-checkbox {
	display: inline-block;
	margin-top: 15px;
	height: 30px;
	margin-right: 10px;
}
.comment-post-checkbox .custom-control-label {
	line-height: 28px;
}
#post_comment.post-comment-force-privatemode-on .comment-post-privatemode {
	opacity: 0.6;
	pointer-events: none;
}
#post_comment.post-comment-force-privatemode-on .comment-post-privatemode .custom-control-label::before {
	border-color: var(--themecolor);
	background-color: var(--themecolor);
	color: #fff;
	box-shadow: none;
}
#post_comment.post-comment-force-privatemode-on .comment-post-privatemode .custom-control-label::after {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}
#post_comment.post-comment-force-privatemode-off .comment-post-privatemode {
	opacity: 0.6;
	pointer-events: none;
}
#post_comment.post-comment-force-privatemode-off .comment-post-privatemode .custom-control-label::before {
	border-color: var(--color-widgets-disabled);
}
html.darkmode #post_comment.post-comment-force-privatemode-off .comment-post-privatemode .custom-control-label::before {
	background: #555;
	border-color: #666;
}
#post_comment.post-comment-force-privatemode-off .comment-post-privatemode .custom-control-label::after {
	background-image: unset;
}
.comment-post-privatemode:before {
	content: attr(tooltip);
}
.comment-post-mailnotice:before {
	content: attr(tooltip);
}
#post_comment.sending .comment-post-checkbox {
	opacity: 0.6;
	pointer-events: none;
}
.comment-btn {
	margin-top: 5px;
	margin-right: 8px;
}

/*编辑状态*/
#post_comment:not(.editing) .hide-on-comment-not-editing {
	display: none !important;
}
#post_comment.editing .hide-on-comment-editing {
	display: none !important;
}
#post_comment.editing .comment-post-use-markdown {
	pointer-events: none;
	opacity: 0.6;
}
#post_comment.editing .comment-post-mailnotice {
	display: none;
}