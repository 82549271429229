@media print {
	#leftbar,
	#post_comment,
	#float_action_buttons,
	#toolbar,
	#share_container {
		display: none !important;
	}
	#primary {
		width: 100% !important;
	}
	#content {
		max-width: unset !important;
	}
	article {
		width: 100% !important;
	}
	article .post-content a {
		text-decoration: underline !important;
	}
	article a::after {
		content: " (" attr(href) ")";
	}
	html > body {
		background-color: white !important;
	}
	article p {
		color: rgba(0, 0, 0, 0.8) !important;
	}
	article {
		font: 13pt Georgia, "Nimbus Roman No9 L", "Songti SC", "Noto Serif CJK SC", "Source Han Serif SC",
			"Source Han Serif CN", STSong, "AR PL New Sung", "AR PL SungtiL GB", NSimSun, SimSun, "TW\-Sung",
			"WenQuanYi Bitmap Song", "AR PL UMing CN", "AR PL UMing HK", "AR PL UMing TW", "AR PL UMing TW MBE",
			PMingLiU, MingLiU, serif !important;
	}
}