#comments_more {
	width: 52px;
	height: 52px;
	display: block;
	margin: 30px auto 10px auto;
}
#comments_more:disabled {
	animation: breath 2s ease infinite;
}
@keyframes breath {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.2);
	}
	100% {
		transform: scale(1);
	}
}
.comments-navigation-more .comments-navigation-nomore {
	text-align: center;
	margin-top: 30px;
	margin-bottom: 10px;
	opacity: 0.6;
}
.comments-navigation .page-item > div {
	cursor: pointer;
}