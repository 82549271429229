.archive-timeline {
	padding-top: 10px;
}
.archive-timeline-title {
	box-shadow: none;
	border: none;
	background: transparent !important;
	font-size: 18px;
	padding: 0;
	margin-left: 1px;
	margin-bottom: 25px;
	padding-top: 7px;
}
.archive-timeline-year {
	font-size: 30px;
	color: var(--themecolor);
	margin-top: 12px !important;
	margin-bottom: 0;
}
.archive-timeline-month {
	font-size: 24px;
	color: var(--themecolor);
	opacity: 0.8;
	margin-top: 12px !important;
	margin-bottom: 0;
	font-weight: unset;
}
.archive-timeline-year + .archive-timeline-title {
	height: 50px;
	margin-top: 30px;
}
.archive-timeline *:not(.archive-timeline-year) + .archive-timeline-title:before {
	width: 12px;
	height: 12px;
	left: -34px;
	top: 18px;
}
.archive-timeline-month + .archive-timeline-title {
	height: 30px;
}
.archive-timeline-month.first-month-of-year + .archive-timeline-title {
	margin-top: -10px;
}
.archive-timeline-title > a {
	width: max-content;
	max-width: 100%;
}
.argon-timeline-time > a:before,
.archive-timeline-title > a:before {
	display: none;
}
.argon-timeline-node:first-child > .archive-timeline-year + .archive-timeline-title {
	margin-top: 0;
}
.archive-timeline h2:before,
.archive-timeline h3:before,
.archive-timeline h2:after,
.archive-timeline h3:after {
	display: none !important;
}