#float_action_buttons {
	position: fixed;
	bottom: 35px;
	right: 20px;
	height: max-content;
	height: -moz-max-content;
	z-index: 1000;
	transition: all 0.3s ease;
}
#float_action_buttons.fabtns-float-left {
	left: 20px;
	right: auto;
}
#float_action_buttons.fabtns-float-left .fabtn-show-on-right {
	display: none;
}
#float_action_buttons:not(.fabtns-float-left) .fabtn-show-on-left {
	display: none;
}
html.is-home.banner-as-cover #float_action_buttons.hidden {
	opacity: 0;
	pointer-events: none;
}
.fabtns-unloaded {
	opacity: 0;
	pointer-events: none;
}
.fabtn {
	width: 42px;
	height: 42px;
	padding: 0;
	overflow: visible;
	display: block;
	margin-top: 8px;
	border: none !important;
	transition: all 0.3s ease;
}
.fabtn:hover {
	color: #fff !important;
	background-color: var(--themecolor) !important;
}
.fabtn:active {
	color: #fff !important;
	background-color: var(--themecolor-dark) !important;
}
.fabtn.fabtn-hidden {
	height: 0 !important;
	opacity: 0;
	margin-top: 0;
	box-shadow: none;
	pointer-events: none;
}
#fabtn_toggle_sides {
	height: 30px !important;
	transform: translateY(8px);
	opacity: 0;
}
#float_action_buttons:hover #fabtn_toggle_sides {
	height: 30px !important;
	transform: translateY(0px);
	opacity: 1;
}
#fabtn_reading_progress {
	height: 30px;
}
#fabtn_reading_progress_bar {
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	border-radius: 4px;
	background: var(--themecolor);
	opacity: 0.08;
}
#float_action_buttons .fabtn:before {
	pointer-events: none;
	position: absolute;
	top: 7px;
	right: 50px;
	line-height: 22px;
	font-weight: normal;
	color: #fff;
	background: #32325d;
	padding: 3px 10px;
	font-size: 12px;
	border-radius: 3px;
	transition: all 0.3s ease;
	transform: translateX(5px);
	opacity: 0;
	width: max-content;
	width: -moz-max-content;
	text-transform: none;
}
#float_action_buttons .fabtn.fabtn-hidden:before {
	opacity: 0 !important;
}
#float_action_buttons.fabtns-float-left .fabtn:before {
	left: 50px;
	right: unset;
	transform: translateX(-5px);
}
#float_action_buttons .fabtn:hover:before {
	transform: translateX(0px);
	opacity: 0.7;
}
#float_action_buttons #fabtn_toggle_sides:before {
	content: attr(tooltip-move-to-left);
	top: 1px;
}
#float_action_buttons.fabtns-float-left #fabtn_toggle_sides:before {
	content: attr(tooltip-move-to-right);
}
#float_action_buttons #fabtn_back_to_top:before {
	content: attr(tooltip);
}
#float_action_buttons #fabtn_reading_progress:before {
	content: attr(tooltip);
	top: 1px;
}
#float_action_buttons #fabtn_open_sidebar:before {
	content: attr(tooltip);
}
#float_action_buttons #fabtn_go_to_comment:before {
	content: attr(tooltip);
}
#float_action_buttons #fabtn_toggle_darkmode:before {
	content: attr(tooltip-darkmode);
}
html.amoled-dark #float_action_buttons #fabtn_toggle_darkmode:before {
	content: attr(tooltip-blackmode);
}
html.darkmode #float_action_buttons #fabtn_toggle_darkmode:before {
	content: attr(tooltip-lightmode);
}
#float_action_buttons #fabtn_toggle_blog_settings_popup:before {
	content: attr(tooltip);
}
#fabtn_toggle_darkmode i[class*='fa'] {
	margin: 0 !important;
}
#fabtn_toggle_darkmode i.fa-lightbulb {
	display: none;
}
html.darkmode #fabtn_toggle_darkmode i.fa-moon {
	display: none;
}
html.darkmode #fabtn_toggle_darkmode i.fa-lightbulb {
	display: inline-block;
}
#float_action_buttons #fabtn_open_sidebar {
	display: none;
}
#sidebar_mask {
	display: none;
	opacity: 0;
}
#fabtn_blog_settings_popup {
	position: fixed;
	right: 85px;
	bottom: 35px;
	padding: 10px 25px;
	opacity: 0;
	width: max-content;
	width: -moz-max-content;
	min-width: 350px;
	max-width: calc(100vw - 170px);
	max-height: calc(100vh - 70px);
	pointer-events: none;
	transform: translateX(10px);
	transition: all 0.3s ease;
	animation: none !important;
}
#float_action_buttons.fabtns-float-left #fabtn_blog_settings_popup {
	left: 85px;
	right: unset;
	transform: translateX(-10px);
}
.blog_settings_opened #fabtn_blog_settings_popup {
	opacity: 1 !important;
	transform: none !important;
	pointer-events: unset;
}
.blog_settings_opened #fabtn_toggle_blog_settings_popup {
	opacity: 1 !important;
	color: #fff !important;
	background-color: var(--themecolor-dark2) !important;
	pointer-events: unset !important;
}
.blog_settings_opened .fabtn {
	pointer-events: none;
	opacity: 0.25;
	overflow: hidden;
}
.blog_settings_opened #fabtn_toggle_sides {
	opacity: 0 !important;
}
#close_blog_settings {
	text-align: right;
	font-size: 12px;
	margin-right: -12px;
	cursor: pointer;
}
.blog-setting-item {
	display: flex;
	flex-flow: row;
	align-items: center;
}
#blog_setting_toggle_darkmode_and_amoledarkmode {
	cursor: pointer;
}
html:not(.amoled-dark) #blog_setting_toggle_darkmode_and_amoledarkmode span:nth-of-type(2) {
	display: none;
}
html.amoled-dark #blog_setting_toggle_darkmode_and_amoledarkmode span:first-of-type {
	display: none;
}
#blog_setting_toggle_darkmode_and_amoledarkmode:before {
	content: attr(tooltip-switch-to-blackmode);
	position: absolute;
	top: -32px;
	left: 50%;
	line-height: 22px;
	font-weight: normal;
	color: #fff;
	background: #32325d;
	padding: 3px 10px;
	font-size: 12px;
	border-radius: 3px;
	transition: all 0.3s ease;
	transform: translateX(-50%) translateY(5px);
	opacity: 0;
	width: max-content;
	width: -moz-max-content;
	pointer-events: none;
}
html.amoled-dark #blog_setting_toggle_darkmode_and_amoledarkmode:before {
	content: attr(tooltip-switch-to-darkmode);
}
#blog_setting_toggle_darkmode_and_amoledarkmode:hover:before {
	transform: translateX(-50%);
	opacity: 0.7;
}
.blog-setting-font,
.blog-setting-shadow {
	text-transform: none;
	padding: 3px 10px;
}
.blog-setting-font:hover,
.blog-setting-shadow:hover {
	color: #fff /*var(--themecolor)*/;
	/*background-color: transparent !important;*/
}
.blog-setting-selector-left {
	margin-right: 0 !important;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	transform: none !important;
}
.blog-setting-selector-right {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	margin-left: 0 !important;
	transform: none !important;
	border-left: 0;
}
html:not(.use-serif) #blog_setting_font_sans_serif {
	color: #fff;
	background: var(--themecolor);
}
html.use-serif #blog_setting_font_serif {
	color: #fff;
	background: var(--themecolor);
}
html:not(.use-big-shadow) #blog_setting_shadow_small {
	color: #fff;
	background: var(--themecolor);
}
html.use-big-shadow #blog_setting_shadow_big {
	color: #fff;
	background: var(--themecolor);
}
.blog-setting-filter-btn {
	border-radius: 50%;
	outline: none !important;
	border: none;
	height: 50px;
	width: 50px;
	margin-left: 5px;
	cursor: pointer;
	font-size: 14px;
	transition: all 0.3s ease;
}
.blog-setting-filter-btn:hover {
	box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}
#blog_setting_filter_off {
	background: rgba(var(--themecolor-rgbstr), 0.05);
	color: var(--themecolor);
}
#blog_setting_filter_sunset {
	background: rgba(255, 255, 200, 1);
	color: #6e5a00;
}
#blog_setting_filter_darkness {
	background: rgba(80, 80, 80, 0.7);
	color: #eee;
}
#blog_setting_filter_grayscale {
	background: rgba(200, 200, 200, 0.8);
	color: #333;
}
#blog_setting_filter_off.active {
	border: 1px solid var(--themecolor);
}
#blog_setting_filter_sunset.active {
	border: 1px solid #6e5a00;
}
#blog_setting_filter_darkness.active {
	border: 1px solid #111;
}
#blog_setting_filter_grayscale.active {
	border: 1px solid #333;
}
html.filter-sunset {
	filter: sepia(30%);
}
html.filter-darkness #primary:after {
	content: "";
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	height: 100vh;
	width: 100vw;
	background: rgba(0, 0, 0, 0.4);
	z-index: 999999999;
	pointer-events: none;
}
html.filter-grayscale {
	filter: grayscale(1);
}
#blog_setting_card_radius_to_default {
	position: relative;
}
#blog_setting_card_radius_to_default:before {
	content: attr(tooltip);
	position: absolute;
	top: -30px;
	left: 50%;
	line-height: 22px;
	font-weight: normal;
	color: #fff;
	background: #32325d;
	padding: 3px 10px;
	font-size: 12px;
	border-radius: 3px;
	transition: all 0.3s ease;
	transform: translateX(-50%) translateY(5px);
	opacity: 0;
	width: max-content;
	width: -moz-max-content;
	pointer-events: none;
}
#blog_setting_card_radius_to_default:hover:before {
	transform: translateX(-50%);
	opacity: 0.7;
}