#wpadminbar {
	position: fixed !important;
	width: max-content !important;
	height: max-content !important;
	width: -moz-max-content !important;
	height: -moz-max-content !important;
	min-height: 55px;
	max-width: 100vw;
	background: var(--themecolor) !important;
	box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
	border-radius: 0 100px 100px 0;
	padding: 10px 20px;
	top: calc(50vh - 27.5px) !important;
	left: 0 !important;
	transform: translateX(calc(-100% + 25px));
	transition: all 0.3s ease;
}
#wpadminbar:hover {
	transform: none !important;
}
#wpadminbar:after {
	content: "\f105";
	font: normal normal normal 20px/1 FontAwesome;
	position: absolute;
	top: 50%;
	transform: translateY(calc(-50% - 2px));
	right: 10px;
	transition: all 0.3s ease;
	color: #fff;
}
#wpadminbar:hover:after {
	opacity: 0;
}
html[class],
html[lang] {
	margin-top: 0 !important;
}