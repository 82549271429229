.leftbar-banner {
	/*background: linear-gradient(150deg,#7795f8 15%,#6772e5 70%,#555abf 94%);*/
	background: var(--themecolor-gradient);
	text-align: center;
	border-radius: var(--card-radius) var(--card-radius) 0 0;
}
.leftbar-banner-title {
	font-size: 20px;
	display: block;
}
.leftbar-banner-subtitle {
	margin-top: 15px;
	margin-bottom: 8px;
	font-size: 13px;
	opacity: 0.8;
	display: block;
}

.leftbar-menu {
	margin-top: 10px;
	margin-left: 0;
	margin-right: 0;
	padding: 0;
}
.leftbar-menu-item {
	height: 36px;
	line-height: 36px;
	list-style: none;
	padding: 0;
	position: relative;
}
.leftbar-menu-item:hover > .leftbar-menu-subitem {
	opacity: 1;
	transform: none;
	pointer-events: unset;
}
.leftbar-menu-subitem {
	position: absolute;
	left: calc(100% + 8px);
	top: 0;
	background: var(--color-foreground);
	width: max-content;
	min-width: 150px;
	z-index: 1;
	height: unset;
	border-radius: 3px;
	padding: 6px 0;
	opacity: 0;
	transform: translateX(-8px);
	pointer-events: none;
	transition: all 0.3s ease;
}
.leftbar-menu-subitem:before {
	content: "";
	width: 8px;
	height: calc(100% + 12px);
	display: block;
	position: absolute;
	top: 0;
	left: -8px;
}
#leftbar_part1_menu > .leftbar-menu-item > .leftbar-menu-subitem {
	left: calc(100% + 12px);
}
#leftbar_part1_menu > .leftbar-menu-item > .leftbar-menu-subitem:before {
	width: 12px;
	left: -12px;
}
.leftbar-menu-subitem > .leftbar-menu-item:first-child a {
	border-radius: 3px 3px 0 0;
}
.leftbar-menu-subitem > .leftbar-menu-item:last-child a {
	border-radius: 0 0 3px 3px;
}
.leftbar-menu-item > a {
	display: block;
	height: 100%;
	width: 100%;
	margin: 0;
	padding: 0 20px;
	overflow-wrap: break-word;
	word-wrap: break-word;
	background-color: transparent;
	color: #32325d !important;
	text-decoration: none;
	outline: none;
	cursor: pointer;
	font-size: 14px;
	transition: background 0.2s ease-in-out;
}
.leftbar-menu-item > a:hover,
.leftbar-menu-item.current > a {
	background-color: var(--color-border-on-foreground);
}
.leftbar-menu-item > a > i {
	margin-right: 8px;
	width: 15px;
	text-align: center;
}
.leftbar-menu-item.leftbar-menu-item-haschildren:after {
	content: "\f0da";
	font: normal normal normal 14px/1 FontAwesome;
	font-size: 14px;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	position: absolute;
	right: 16px;
	top: 0;
	line-height: 36px;
	opacity: 0.6;
	transform: translateY(1px);
	transition: all 0.3s ease;
	pointer-events: none;
}

.leftbar-search-button {
	padding-top: 0 !important;
	transition: all 0.3s cubic-bezier(0.4, 0, 0, 1);
}
.leftbar-search-button.open {
	padding: 0 15px 18px 15px;
	margin-top: -9px;
}
#leftbar_search_container {
	transition: width 0.3s cubic-bezier(0.4, 0, 0, 1), height 0.3s cubic-bezier(0.4, 0, 0, 1), box-shadow 0.15s ease,
		transform 0.15s ease;
	height: 30px;
	transform: unset !important;
	text-transform: capitalize;
	background-color: var(--color-border-on-foreground-deeper);
	color: var(--color-text-deeper);
}
html.darkmode.amoled-dark #leftbar_search_container {
	background: #151515;
}
.leftbar-search-button.open #leftbar_search_container {
	height: 45px;
}
.leftbar-search-button:not(.open) #leftbar_search_container:focus-within {
	box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}
#leftbar_search_input {
	position: absolute;
	left: -1px;
	top: -1px;
	width: calc(100% + 2px);
	height: calc(100% + 2px);
	transition: all 0.3s cubic-bezier(0.4, 0, 0, 1);
	opacity: 0;
	cursor: pointer;
	user-select: none;
}
.leftbar-search-button.open #leftbar_search_input {
	opacity: 1;
	cursor: text;
	user-select: all;
	box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

#leftbar_part2 {
	margin-top: 10px;
	transition: all 0.3s ease;
}
#leftbar_part2.sticky {
	position: fixed;
	width: var(--sidebar-width);
	top: 80px;
}
body.leftbar-can-headroom.headroom---unpinned #leftbar_part2.sticky {
	top: 10px;
}
html.navbar-absolute #leftbar_part2.sticky {
	top: 10px !important;
}
#leftbar_part2_inner {
	max-height: calc(100vh - 110px);
	overflow-y: auto;
	padding: 10px;
}
#leftbar_part2_inner::-webkit-scrollbar {
	width: 6px;
}
#leftbar_part2_inner::-webkit-scrollbar-track {
	background: transparent;
}
#leftbar_part2_inner::-webkit-scrollbar-thumb {
	border-width: 1px;
	background-color: rgba(0, 0, 0, 0.2);
}
html.darkmode #leftbar_part2_inner::-webkit-scrollbar-thumb {
	border-width: 1px;
	background-color: rgba(255, 255, 255, 0.2);
}
#leftbar_part2_inner::-webkit-scrollbar-button {
	height: 5px;
	pointer-events: none;
}
.sidebar-tab-switcher {
	font-size: 13px;
	padding: 0 !important;
}
.sidebar-tab-switcher > a {
	padding-bottom: 5px;
	border-bottom: 1px solid transparent;
	transition: border-bottom 0.2s ease;
}
.sidebar-tab-switcher > a.active {
	border-bottom: 1px solid var(--themecolor);
}
html.darkmode .sidebar-tab-switcher > a.active {
	border-bottom: 1px solid var(--themecolor-light);
}

#leftbar_overview_author_image {
	width: 100px;
	height: 100px;
	margin: auto;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	background-color: rgba(127, 127, 127, 0.1);
}
#leftbar_overview_author_name {
	margin-top: 15px;
	font-size: 18px;
}
#leftbar_overview_author_description {
	font-size: 14px;
	margin-top: -4px;
	opacity: 0.8;
}
.site-state {
	overflow: hidden;
	line-height: 1.4;
	white-space: nowrap;
	text-align: center;
	margin-top: 15px;
}
.site-state-item {
	display: inline-block;
	border-left: 1px solid var(--color-border-on-foreground-deeper);
	padding: 0 10px;
}
.site-state-item:first-child {
	border-left: none !important;
}
.site-state-item > a {
	cursor: pointer;
}
.site-state-item-count {
	display: block;
	text-align: center;
	color: #32325d;
	font-weight: bold;
	font-size: 16px;
}
.site-state-item-name {
	font-size: 13px;
	color: #525f82;
}
.tag.badge {
	font-size: 14px;
	text-transform: none;
	transition: background 0.2s ease;
	background: var(--color-border-on-foreground);
	border: 1px solid var(--color-border-on-foreground-deeper);
	padding: 5px 10px;
	margin-right: 12px;
	margin-bottom: 15px;
}
.tag.badge:hover {
	background: var(--color-border-on-foreground-deeper);
}
.tag-num {
	font-size: 12px;
	opacity: 0.7;
}
.site-author-links {
	display: flex;
	flex-wrap: wrap;
	justify-content: left;
	margin-top: 15px;
}
.site-author-links-item {
	display: inline-block;
	width: 50%;
	border-radius: 4px;
	margin-top: 5px;
	transition: background 0.2s ease;
	background: transparent;
}
.site-author-links-item:hover {
	background: var(--color-border-on-foreground);
}
.site-author-links-item > a {
	display: block;
	width: 100%;
	padding: 2px 8px;
	font-size: 15px;
	color: #32325d !important;
	text-align: left;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.site-friend-links-title {
	margin-top: 25px;
	border-top: 1px dotted var(--color-border-on-foreground-deeper);
	padding-top: 15px;
}
.site-friend-links-ul {
	margin-top: 8px;
	padding: 3px 0 0;
}
.site-friend-links-item {
	margin: 0;
	padding: 0;
	list-style: none;
	margin-bottom: 3px;
}
.site-friend-links-item > a {
	max-width: calc(var(--sidebar-width) + 40px);
	box-sizing: border-box;
	display: inline-block;
	max-width: 100%;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	color: #32325d;
	border-bottom: 1px solid #999;
}
#leftbar_announcement {
	margin-bottom: 10px;
	background: var(--themecolor-gradient);
}
html.darkmode #leftbar_announcement {
	background: var(--color-foreground);
}
.leftbar-announcement-body {
	padding: 15px 1.2em;
	padding-bottom: 22px;
}
.leftbar-announcement-title {
	font-size: 18px;
}
.leftbar-announcement-content {
	font-size: 15px;
	line-height: 1.8;
	padding-top: 8px;
	opacity: 0.9;
}
#leftbar_tab_tools ul,
#leftbar_tab_tools ol {
	list-style: none;
	padding: 0;
}
#leftbar_tab_tools ul li {
	padding-top: 10px;
	padding-bottom: 10px;
	border-bottom: var(--color-border-on-foreground) solid 1px;
}
#leftbar_tab_tools ul li:first-child {
	padding-top: 5px;
}
#leftbar_tab_tools > div > h6:first-child,
#leftbar_tab_tools .wp-block-group__inner-container > h6:first-child {
	font-size: 17px;
	font-weight: 600;
	position: relative;
	display: block;
	width: max-content;
}
#leftbar_tab_tools > div > h6:first-child:after,
#leftbar_tab_tools .wp-block-group__inner-container > h6:first-child:after {
	content: "";
	display: block;
	background: var(--themecolor);
	width: 30px;
	height: 9px;
	position: absolute;
	left: 0;
	bottom: -1px;
	border-radius: 10px;
	opacity: 0.25;
	pointer-events: none;
}
.wp-calendar-table caption {
	font-size: 14px;
	text-align: center;
	opacity: 0.7;
}
.wp-block-calendar table * {
	background: transparent !important;
	border: none !important;
	padding-top: 5px;
	padding-bottom: 8px;
}
.wp-block-calendar table th {
	opacity: 0.6;
}
.wp-block-calendar tbody td a {
	position: relative;
	text-decoration: none;
}
.wp-block-calendar tbody td a:before {
	content: "";
	display: block;
	background: var(--themecolor);
	position: absolute;
	width: 25px;
	height: 25px;
	border-radius: 20px;
	opacity: 0.2;
	transform: translateX(-2px);
	z-index: 0;
}
.wp-calendar-nav-prev a,
.wp-calendar-nav-next a {
	text-decoration: none !important;
	border: 1px solid var(--themecolor);
	color: var(--themecolor);
	padding: 1px 8px;
	border-radius: 19px;
	font-size: 14px;
	transition: all 0.25s ease;
	user-select: none;
}
.wp-calendar-nav-prev a:hover,
.wp-calendar-nav-next a:hover {
	background-color: var(--themecolor);
	color: #fff !important;
	box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.wp-calendar-nav {
	padding-bottom: 12px;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	align-content: center;
}
html.darkmode .wp-calendar-table caption,
html.darkmode .wp-block-calendar tbody td {
	color: #eee;
}