.zoomify {
	cursor: pointer;
	cursor: -webkit-zoom-in;
	cursor: zoom-in;
}
.zoomify.zoomed {
	cursor: -webkit-zoom-out;
	cursor: zoom-out;
	padding: 0;
	margin: 0;
	border: none;
	border-radius: 0;
	box-shadow: none;
	position: relative;
	z-index: 1501;
}
.zoomify-shadow {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	display: block;
	z-index: 1500;
	background: rgba(0, 0, 0, 0.3);
	opacity: 0;
}
.zoomify-shadow.zoomed {
	opacity: 1;
	cursor: pointer;
	cursor: -webkit-zoom-out;
	cursor: zoom-out;
}
.noscroll {
	overflow: hidden !important;
}
body.noscroll:before {
	opacity: 0 !important;
}